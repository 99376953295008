import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "publish-the-app"
    }}>{`Publish the App`}</h1>
    <p>{`Since this is your first time you connect to this Business Central instance,
Visual Studio Code will ask you to enter the `}<strong parentName="p">{`username`}</strong>{` and `}<strong parentName="p">{`password`}</strong>{` of a
user with permission to publish extensions (it will remember it for future requests, though).
Visual Studio Code also doesn't like when you click anywhere else when it asks you for those credentials,
and will cancel the operation if you do 🥴.
Thus, you'll need to remember the username (type it by hand) and copy/paste the password
(click the `}<strong parentName="p">{`User 1`}</strong>{`/`}<strong parentName="p">{`User 2`}</strong>{` name in ContainerHavn to place it on your clipboard) 💡.`}</p>
    <p>{`With both the username and password prepared, hit CTRL+F5 to run the `}<em parentName="p">{`AL: Publish without debugging`}</em>{` command 🚀.
Once it's successfully published, it should open up the login page automatically.
Log in and navigate to the `}<em parentName="p">{`Customer List`}</em>{`:`}</p>
    <figure>
  <img src="/public/images/vindfang-demo-customers.png" title='The "Tell me" functionality in Business Central, searching for "customers"' />
  <figcaption>
    Using the <em>Tell me</em> functionality (aka. search), you can quickly find
    the pages you're looking for, without having to manually navigate the user
    interface.
  </figcaption>
    </figure>
    <figure>
  <img src="/public/images/vindfang-demo-my-first-app-says-hello.png" title='A dialog box with the message "My first app says hello!"' />
  <figcaption>
    A dialog box pops up with the message "My first app says hello!", as
    expected.
  </figcaption>
    </figure>
    <h2 {...{
      "id": "next-steps"
    }}>{`Next steps`}</h2>
    <p>{`Congratulations, you've successfully created your first extension! 🥳`}</p>
    <p>{`The logical next step, would be to save it somewhere safe, in case your computer dies.`}<br parentName="p"></br>{`
`}{`Take it from someone who's been there `}{`—`}{` you don't want to lose a day's worth of
work to a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Blue_screen_of_death"
      }}>{`BSoD`}</a>{`...it hurts...`}</p>
    <p>{`For this, we use `}<a parentName="p" {...{
        "href": "https://dev.azure.com/skillcore"
      }}>{`Azure DevOps`}</a>{`.`}</p>
    <Navigation previous="/my-first-extension/create-sandbox" next="/my-first-extension/publish-to-devops" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      